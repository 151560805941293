import { defineStore } from "pinia";
import {
  getNotifications,
  resetPassword,
  updateUser,
  markAsRead,
} from "@/services/UserService";
import { useLocalStorage } from "@vueuse/core";
import { acceptHMRUpdate } from "pinia";

export const useUserStore = defineStore("userStore", () => {
  const user = useLocalStorage("userStore:user", {});
  const twoFaInProgress = useLocalStorage("userStore:twoFaInProgress", false);
  const notifications = ref([]);
  const loginInProgress = ref(false);

  const isPremiumUser = async () => {
    if (
      user.value.subscription == "admin" ||
      user.value.subscription == "premium-free"
    ) {
      return true;
    } else {
      if (!user.value || !user.value.id) return false;
      //get the subscription status from payment API
      const apiHeaders = await useApiHeaders(true);
      try {
        const { valid } = await $fetch(`/api/user/${user.value.id}/valid`, {
          ...apiHeaders,
        });
        return valid;
      } catch (error) {
        return false;
      }
    }
  };

  const signedIn = computed(() => {
    const firebaseUser = useCurrentUser();
    //check if user has atleast one key in the user object

    const isLoggedIn =
      firebaseUser.value &&
      Object.keys(user.value).length > 0 &&
      user.value.twoFaInProgress !== true &&
      user.value.id != "";

    return isLoggedIn;
  });

  const isAdmin = computed(async () => {
    const user = useCurrentUser();
    if (!user || !user.value) return false;
    const result = await user.value.getIdTokenResult();

    return (await result?.claims?.role) === "admin";
  });

  function resetPassword(credentials) {
    return resetPassword(credentials).then(({ data }) => {
      this.setUser(data.data);
      return data.data;
    });
  }

  function setMode(userData) {
    const newData = { id: user.value.id, ...userData };
    return updateUser(newData).then((response) => {
      user.value.mode = response.mode;
      localStorage.setItem("sessionUser", JSON.stringify(user.value));
    });
  }

  async function setTwoFaVerified(verified) {
    await updateUser({ id: user.value.id, twoFaVerified: verified });
    user.value.twoFaVerified = verified;
  }

  function addUserData(key, value) {
    user.value[key] += value;
    localStorage.setItem("sessionUser", JSON.stringify(user.value));
  }

  function setPnl(pnl) {
    user.value.pnl += pnl;
    localStorage.setItem("sessionUser", JSON.stringify(user.value));
  }

  function setTradePosition(decrease) {
    if (decrease) {
      user.value.tradePosition--;
    } else {
      user.value.tradePosition++;
    }
  }

  async function getUserNotifications() {
    if (notifications.value.length > 0) {
      return notifications.value;
    }
    try {
      const data = await getNotifications();
      notifications.value = data;
      return data;
    } catch (error) {}
    return [];
  }

  function markNotificationAsRead() {
    const ids = [];
    notifications.value.forEach((notification) => {
      notification.read = true;
      ids.push(notification._id);
    });
    return markAsRead(ids, user.value.id).then((data) => {});
  }

  function $reset() {
    user.value = {};
    twoFaInProgress.value = false;
    notifications.value = [];
    loginInProgress.value = false;
  }

  return {
    user,
    notifications,
    signedIn,
    isAdmin,
    isPremiumUser,
    resetPassword,
    setMode,
    addUserData,
    setTwoFaVerified,
    setPnl,
    setTradePosition,
    getUserNotifications,
    $reset,
    markNotificationAsRead,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
